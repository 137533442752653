<template>
    <div class="container">
        <p>Impressum</p>
        <p>Kontakt</p>
        <p>j.voland@gmx.ch</p>
        <router-link to="/">Back to home</router-link>
    </div>
</template>

<script>

export default {
    name: 'PageImpressum'
}
</script>

<style scoped>
.container > * {
    padding: 10px;
}
</style>
